import React from "react";
import { LoadingContainer } from "./styles";
import GridLoader from "react-spinners/GridLoader";


const LoadingComponent = ({ isLoading }: { isLoading: boolean }) => {
  return (
    <LoadingContainer isLoading={isLoading}>
      <GridLoader color={"#3F4A59"} />
    </LoadingContainer>
  );
};

export default LoadingComponent;
