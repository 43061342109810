import React from 'react';
import "./error.css";

interface IError{
    message:string;
}
const Error = ({message}:IError) => (
 <div className="error">
     <div className="error__image-container">
         <img className="error__image" src="https://bonnadona-public.s3.amazonaws.com/assets/alicanto_logo.svg"/>
     </div>
     <div className="error__title">
         {message}
     </div>
 </div>
);

export default Error;