import styled from "styled-components";

export const LoadingContainer = styled.div`
    display:${({isLoading}: {isLoading: boolean})=>isLoading?'flex': 'none'};
    position: absolute;
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, 0.5);
    z-index: 9999999999;
    top: 0;
`
//