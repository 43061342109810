import React, { useRef, useState } from "react";
import "./App.css";
import SignatureCanvas from "react-signature-canvas";
import { ToastContainer } from "react-toastify";
import { trackPromise, usePromiseTracker } from "react-promise-tracker";
import Error from "./Error";
import LoadingComponent from "./Loading";
import { useParams } from "react-router-dom";
import { sendSignature } from "./api/signature";
function App() {
  const $signature = useRef<SignatureCanvas>(null);
  const [isSing, setIsSing] = useState(false);
  const { promiseInProgress } = usePromiseTracker();
  const { token } = useParams<{ token: string }>();
  const onSubmit = async () => {
    const img =
      $signature.current?.getTrimmedCanvas().toDataURL("image/png") ?? "";
    try {
      await trackPromise(sendSignature(img, token));
      setIsSing(true);
    } catch (e) {}
  };

  return (
    <div style={{ position: "relative" }}>
      {!isSing ? (
        <div className="container">
          <div className="signature-container">
            <SignatureCanvas
              ref={$signature}
              penColor="black"
              canvasProps={{ className: "signature" }}
            />
          </div>
          <div className="signature__buttons">
            <button
              className="signature__button"
              onClick={() => {
                $signature.current?.clear();
              }}
            >
              Limpiar
            </button>
            <button className="signature__button" onClick={onSubmit}>
              Enviar
            </button>
          </div>
          <footer>"Deveop by LYA Electronic v3 prod"</footer>
        </div>
      ) : (
        <Error message={"Firma finalizada con exito"} />
      )}
      <LoadingComponent isLoading={promiseInProgress} />
    </div>
  );
}

export default App;
