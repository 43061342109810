import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import "react-toastify/dist/ReactToastify.css";
import App from './App';
import {
    HashRouter,
    Switch,
    Route,
    Link
} from "react-router-dom";
import reportWebVitals from './reportWebVitals';
import Error from "./Error";
import {ToastContainer} from "react-toastify";

ReactDOM.render(
    <HashRouter>
            <Switch>
                <Route path="/signature/:token">
                    <App />
                </Route>
                <Route>
                    <Error message={"No se pudo encontrar la ruta."}/>
                </Route>
            </Switch>
        <ToastContainer />
    </HashRouter>
   ,
  document.getElementById('root')
);




// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
