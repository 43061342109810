import { url } from "../urls";
import axios from "axios";
import path from "path";
import { showAlertError } from "../../Alerts";
require("dotenv").config({ path: "../../../.env" });

export const sendSignature = async (sign: string, token: string) => {
  const Baseurl = `${url.alicanto}/committee/sign`;
  try {
    await axios.post(
      Baseurl,
      { sign },
      {
        headers: {
          Authorization: `token ${token}`,
        },
      }
    );
  } catch (e) {
    if (e.response.data.message === "Invalid Sign Token") {
      showAlertError("A expirado tiempo para firmar");
      return Promise.reject("A expirado tiempo para firmar");
    }
    showAlertError("No se pudo enviar la firma");
    return Promise.reject("No se pudo enviar la firma");
  }
};
